.sektionsloggaVitt {
	max-width: 1000px;
	width: 87%;
	height: min-content;
	filter: drop-shadow(5px 5px 5px #222);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.imageContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
}

/* Content */
.indexContent {
	padding-top: 0 !important;
}

.omContainer {
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 0.2s ease-in-out;
}

.omContainer.open {
	grid-template-rows: 1fr;
	padding-bottom: 0.5rem;
}

.omContainer > * {
	overflow: hidden;
}

.om {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 2rem;
}

.om .sektionslogga {
	width: calc(100% - 20px);
	height: auto;
}

.noMarginLine {
	margin-top: -1px;
	margin-bottom: 0;
}

.visaOmKnappDiv {
	display: flex;
	justify-content: center;
	margin-bottom: -1rem;
}

.visaOmKnapp {
	background-color: var(--clr1);
	border-radius: 0 0 0.7rem 0.7rem;

	width: 120px;
	font-size: 1rem;
	padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	justify-content: center;
}

.aktuelltInnehåll {
	margin-bottom: 10px;
	border-radius: 0.3rem;
}

.feedWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	overflow: hidden;
}

.feedColumn {
	border-radius: 0.3rem;
}

#happenings h2 {
	text-align: left;
	margin-top: 0rem;
}

.sectionButton {
	text-align: center;
	display: block;
	margin: auto;
	margin-top: 1rem;
	width: -moz-fit-content;
	width: fit-content;
	text-decoration: none;
}

.sectionRow {
	display: grid;
	gap: 0px 2rem;
	grid-template-areas: ". .";
}

.sectionRow h2 {
	margin-top: 0px;
}

.sektionskalMånadOchBokningar {
	margin-top: 2rem;
	display: flex;
	gap: 1rem;
	flex-wrap: nowrap;
	justify-content: center;
}

@media screen and (max-width: 1000px) {
	.sektionskalMånadOchBokningar {
		display: block;
	}
}

@media screen and (max-width: 850px) {
	.feedWrapper {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
	}
}

@media screen and (max-width: 800px) {
	.om {
		display: flex;
		flex-wrap: wrap;
	}

	.sectionRow {
		display: flex;
		flex-wrap: wrap;
		row-gap: 1.5rem;
	}
}
