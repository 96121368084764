.indexBg {
	width: 102%;
	height: 300px;
	overflow: hidden;
	position: relative;
	transform: translateX(-1%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.carouselRoot {
	height: 100%;
	width: 100%;
}

.carouselSlide {
	height: 100%;
}

.carouselSlideInner {
	position: relative;
	width: 100%;
	height: 100%;
}

.carouselBackgroundImage {
	filter: blur(10px) brightness(70%);
	transform: scale(1.1);
	z-index: 0;
}

.carouselMainImage {
	z-index: 1;
}

.carouselIndicators {
	bottom: 1rem;
	z-index: 2;
}

.carouselIndicators button {
	width: 8px;
	height: 8px;
	transition: width 250ms ease;
	background-color: rgba(255, 255, 255, 0.7);
}

.carouselIndicators button[data-active] {
	width: 24px;
	background-color: white;
}

.sektionsloggaVitt {
	max-width: 1000px;
	width: 87%;
	height: min-content;
	--text-blur-color: rgba(33, 33, 33, 0.4);
	filter: drop-shadow(-2px -2px 2px var(--text-blur-color))
		drop-shadow(2px -2px 2px var(--text-blur-color))
		drop-shadow(-2px 2px 2px var(--text-blur-color))
		drop-shadow(2px 2px 2px var(--text-blur-color));
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.imageContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
}
