/* Aktuellt feed */

/* Post preview dvs varje länk */
.postWrapper {
	margin: 5px;
	background-color: #fff;
	border-radius: 0.3rem;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.44);
	overflow: hidden;
	padding: 5px;
	height: auto;
	min-height: 110px;
}

.gridItem {
	background-color: #fff;
	border-radius: 0.3rem;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.44);
	overflow: hidden;
	padding: 1rem;
}

.postLink {
	display: block;
	text-decoration: none;
	color: inherit;
	height: 100%;
}

.gridItem .postLink {
	display: flex;
	flex-direction: column;
}

/* Common styles */
.image {
	margin-bottom: 1rem;
	overflow: hidden;
	border-radius: 0.3rem;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.content {
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 1.2rem;
	font-weight: bold;
	margin: 0;
	color: var(--clr1);
}

.postMeta {
	font-size: 0.9rem;
	color: #666;
	margin: 0;
}

.subtitle {
	font-size: 1rem;
	font-weight: 500;
	padding-bottom: .25rem;
	padding-top: .25rem;
	color: #222;
}

.description {
	font-size: 0.8rem;
	color: #333;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.description p {
	padding: 0;
}

.eventDate {
	font-size: 0.9rem;
	color: #666;
	margin-top: -0.3rem;
}

.body {
	font-size: 0.95rem;
	color: #333;
	overflow: hidden;
}

/* Grid specific styles */
.gridItem .image {
	width: 100%;
	height: 200px;
}

.gridItem .body {
	display: -webkit-box;
	line-clamp: 4;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

/* List specific styles */
.postWrapper .image {
	float: left;
	margin-right: 1rem;
	width: 120px;
	height: 90px;
}

.postWrapper .content {
	overflow: hidden;
}

.postWrapper .body {
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.passedDate {
	font-size: 0.9rem;
	color: var(--clr5);
}

.postPreview {
	height: 100%;
}
.postPreview h2 {
	font-size: 1.5rem;
	color: #1d1d1f;
	margin-bottom: 2px;
}

.postPreview h3 {
	font-size: 1.2rem;
	font-weight: 500;
	color: #333;
	margin: 6px 0px;
}

.postPreview p.meta {
	font-size: 1.1rem;
	padding: 0;
	margin-bottom: 0.5rem;
	color: #1d1d1f;
}

.postPreview p.subtitle {
	font-size: 1.2rem;
	color: var(--clr5);
	padding: 0;
	margin-bottom: 0rem;
}

.postPreview div.body p {
	padding: 0;
	color: #333;
	overflow: hidden;
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.postPreview a {
	text-decoration: none;
	height: 100px;
}

.postPreview hr {
	margin-top: 0px;
}

.preview * {
	text-decoration: none;
	border-radius: 0.1rem !important;
}

.preview .postPreview {
	margin: 0;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr;
	gap: 0 0.5rem;
	grid-template-areas:
		"image meta"
		"image content";
}

.preview div.image {
	width: 120px;
	height: 100%;
	grid-area: image;
	/* overflow: hidden; */
}

.preview div.image img {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.preview .postMeta {
	grid-area: meta;
	overflow: hidden;
	color: black;
	gap: 0.5rem;
	width: 100%;
	height: 50px;
}

.preview .postMeta div {
	gap: 0.5rem;
}

.preview .postMeta p {
	padding: 0px;
	margin: 0px;
	padding-top: 0.3rem;
	font-size: 1rem;
}

.preview .postContent {
	grid-area: content;
	height: 100%;
}

.preview .postPreview h2 {
	margin-top: 0;
	font-size: 1.2rem;
	display: -webkit-box;
	overflow: hidden;
	white-space: nowrap;
	max-width: 30rem;
}

.preview p.subtitle {
	font-size: 1rem;
	display: inline;
}

.preview .postPreview .subtitle {
	overflow: hidden;
	display: -webkit-box;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.preview .postPreview .body p {
	all: unset;
	width: 100%;
	word-break: break-word;
	overflow-wrap: break-word;
	color: #1d1d1f;
	font-size: 1rem;
	overflow: hidden;
	display: -webkit-box;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.preview .postPreview .body strong {
	color: #1d1d1f;
}

.preview {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
}

.small .preview {
	grid-template-rows: repeat(4, 110px);
}

.long .preview {
	grid-auto-rows: 110px;
}

@media screen and (max-width: 550px) {
	.preview .postPreview {
		width: calc(100% - 3rem);
		grid-template-areas:
			"image meta"
			"image content";
	}
}

.preview .postWrapper * {
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}

@media (hover: hover) and (pointer: fine) {
	.postWrapper:hover,
	.postWrapper:focus,
	.gridItem:hover,
	.gridItem:focus {
		background-color: #f5f5f5;
	}
}
