/* Cards */
.linkCard {
	background-color: rgb(237, 237, 238);
	color: #444 !important;
	box-shadow: 0px 0px 4px 1px rgba(100, 100, 100, 0.3);
	width: min-content;

	padding: 0.2rem 0;
	padding-right: 1rem;
	white-space: nowrap;

	border-radius: 0.3rem;
	margin: 0.5rem;

	font-size: 1.2rem;

	display: inline-block;
}

.linkCard:hover,
.linkCard:focus {
	box-shadow: 0px 0px 6px 1px rgba(100, 100, 100, 0.3);
	background-color: #ccc !important;
	transition: 0.1s ease-in-out;
}

.linkCard[disabled] {
	background-color: lightgray !important;
	cursor: not-allowed !important;
}

.linkCard svg {
	color: var(--clr3);
	margin: 0 0.3rem 0 0.5rem;
}

.linkCard a {
	text-decoration: none;
	color: inherit;
}

/* Copy button */
.copyButton {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.copyButton .toolTipText {
	visibility: hidden;
	opacity: 0;
	/* width: 120px; */
	background-color: var(--clgra);
	color: #fff;
	text-align: center;
	padding: 0.3rem 16px;
	border-radius: 0.3rem;

	position: absolute;
	z-index: 1;

	/* Position */
	bottom: 100%;
	left: 50%; /* sätter vänsterkanten i mitten */
	transform: translateX(-50%); /* förskjuter till vänster med halva bredden */

	transition: all 0.2s ease-in-out;
}
.copyButton .toolTipText::after {
	content: " ";
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: var(--clgra) transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the tooltip container */
.copyButton .toolTipText.showing {
	visibility: visible;
	opacity: 1;
	transition: all 0.18s ease-in-out;
}

/* Toggle */

.toggle {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 1.5rem;
}

.toggle input {
	opacity: 0;
	width: 0;
	height: 0;
	margin: 0;
}

.toggle span {
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgb(200, 200, 200); /* Av */
	padding: 5%;
	transition: 0.3s;
	border-radius: 0.75rem; /* Halva höjden */
	box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.3);
}

/* Slider button */
.toggle span:before {
	position: relative;
	z-index: 10;
	content: "";
	height: 100%;
	aspect-ratio: 1 / 1;
	background-color: #fff;
	border-radius: 50%;
	transition: 0.3s;
}

.toggle span:hover:before {
	box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
}

.toggle input:checked + span {
	background-color: var(--clr1); /* På */
}

.toggle input:checked + span:before {
	transform: translateX(1.5rem);
}

.toggle strong {
	position: absolute;
	width: max-content;
	left: 100%;
	top: 0.1rem;
	font-size: 1rem;
	margin-left: 10px;
	cursor: pointer;
}
